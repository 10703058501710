import Vue from 'vue'
import Router from 'vue-router'
import DashBoard from './views/DashBoard.vue'
import {
  TokenService
} from '@/services/storage.service'
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
      path: '/',
      component: DashBoard,
      children: [{
          path: '',
          component: () => import('./components/dashboard/DashBoardHome.vue'),
          name: 'index',
        },
        {
          path: 'plan',
          name: 'user-plan',
          component: () => import('./views/Plan.vue')
        },
        {
          path: 'tdrd',
          name: 'user-DigitalCreditCards',
          component: () => import('./views/DigitalCreditCard.vue')
        },
        {
          path: 'pagos',
          name: 'user-payments',
          component: () => import('./views/Payment.vue')
        },
        {
          path: 'contratos',
          name: 'user-agreements',
          component: () => import('./views/Document.vue'),
          props: true
        },
        {
          path: 'notificaciones',
          name: 'user-notifications',
          component: () => import('./views/Alert.vue')
        },
        {
          path: 'usuario',
          name: 'user-profiles',
          component: () => import("./views/user/ProfilePage.vue")
        },
        {
          path: 'datos-crediticios',
          name: 'user-credit-data',
          component: () => import("./views/user/CreditScorePage.vue")
        },
        {
          path: 'desvare',
          name: 'user-desvare',
          component: () => import("./views/user/DesvarePage.vue")
        },

        {
          path: 'tarjeta-credito/rechazo',
          name: "tc-reject",
          component: () => import("./views/user/CreditCardRejectPage.vue")
        },
        {
          path: 'tarjeta-credito/solicitud',
          name: "tc-request",
          component: () => import("./views/user/CreditCardRequestPage.vue"),
          props: {is_digital:false}
        },
        {
          path: 'tarjeta-credito/solicitud-digital',
          name: "tc-request-digital",
          component: () => import("./views/user/CreditCardRequestPage.vue"),
          props: {is_digital:true}
        },
        {
          path: 'pazysalvo',
          name: "paz-y-salvo",
          component: () => import("./views/Documentps.vue")
        }
      ]
    },
    // {
    //   path: "/admin",
    //   name: 'admin',
    //   component: () => import("./views/admin/DashBoard.vue")
    // },
    // {
    //   path: "/agreement-payment",
    //   name: 'admin-agreements',
    //   component: () => import("./views/admin/AgreementPayment.vue"),
    // },
  ]
})


router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const token = to.query ? to.query.token : null;
  const loggedIn = !!TokenService.getToken(token);
  if (!isPublic && !loggedIn) {
    window.location = '/signin?' + to.fullPath.substr(1);
  }
  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next();
  }

  next();
});

export default router;