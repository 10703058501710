<template>
  <div
    id="app"
    class="m-grid m-grid--hor m-grid--root m-page"
  >
    <v-app>
      <AppHeader
        :email="generalInformation.email"
        :name="generalInformation.first_name"
        navbar
      />
      <button
        class="m-aside-left-close  m-aside-left-close--skin-light "
        id="m_aside_left_close_btn"
      ><i class="la la-close"></i></button>
      <AppMenu :activeCreditData="anyPayment" />
      <v-main>
        <router-view />
      </v-main>
      <ScrollableModal />
      <AppFooter />
    </v-app>
  </div>
</template>

<script>

import AppFooter from "@/components/layout/AppFooter.vue";
import AppMenu from "@/components/layout/AppMenu.vue";
import AppHeader from "@/components/layout/AppHeader.vue";
import ScrollableModal from "@/components/ui/ScrollableModal";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    AppFooter,
    AppMenu,
    AppHeader,
    ScrollableModal,
  },
  computed: {
    ...mapState({
      plan: state => state.plans.plan,
      generalInformation: state => state.users.generalInformation,
    }),
    ...mapGetters({
      anyPayment: "users/anyPayment",
    }),
  }
}
</script>

<style>
</style>

<style lang="scss">
@import "@/styles/style.scss";

.modal-title {
  font-size: 1.5em;
}

.qnt-lead {
  font-size: 1.5em;
}
</style>
