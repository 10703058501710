<template>
<div class="modal fade" id="m_modal_1_2" tabindex="-1" role="dialog" aria-labelledby="scrollableModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="scrollableModal">{{alert.subject}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="m-scrollable" data-scrollbar-shown="true" data-scrollable="true" data-height="200">
                    <div><span v-html="alert.content"></span></div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "ScrollableModal",
    props:{
        qntModalContent:{
            type:String,
            default:""
        },
        qntModalTitle:{
            type:String,
            default:""
        },
    },
    computed: {
    ...mapGetters({
        alert: 'alerts/currentAlert',
    })
  }
}
</script>