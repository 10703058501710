import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import VueCurrencyFilter from 'vue-currency-filter';
import vSelect from 'vue-select'
import "./plugins/vuetify-money.js";
// import fontawesine icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFileSignature } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faFileSignature);
Vue.component("font-awesome-icon", FontAwesomeIcon);


import Vuelidate from 'vuelidate'
Vue.use(Vuelidate);

import vuelidateErrorExtractor, {
  templates
} from "vuelidate-error-extractor";

import vuetify from '@/plugins/vuetify'
import "./styles/style.scss";

Vue.use(vuelidateErrorExtractor, {
  /**
   * Optionally provide the template in the configuration. 
   * or register like so Vue.component("FormField", templates.singleErrorExtractor.foundation6)
   */
  template: templates.singleErrorExtractor.foundation6, // you can also pass your own custom template
  i18n: false,
  messages: {
    required: 'El campo {attribute} es obligatorio',
    accepted: 'Se debe aceptar {attribute}',
  },
});

const moment = require('moment');
require('moment/locale/es');
Vue.use(require('vue-moment'), {
  moment
});

Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: '.',
  fractionCount: 0,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true
});

Vue.component('v2-select', vSelect);

Vue.config.productionTip = false

Vue.config.errorHandler = function (err, vm, info) {
  console.log(`Error: ${err.toString()}\nInfo: ${info}`)
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');