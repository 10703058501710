<template>
  <header
    id="m_header"
    class="m-grid__item m-header header-container"
    m-minimize-offset="200"
    m-minimize-mobile-offset="200"
  >
    <div class="m-container m-container--fluid m-container--full-height">
      <div class="m-stack m-stack--ver m-stack--desktop">

        <!-- BEGIN: Brand -->
        <div class="m-stack__item m-brand  m-brand--skin-light ">
          <div class="m-stack m-stack--ver m-stack--general header-container-in">
            <div :class="'m-brand__logo logo-container' + (navbar?'':' text-center')">
              <router-link
                to="/"
                class="m-brand__logo-wrapper"
              >
                <img
                  alt="logo qnt"
                  height="30"
                  width="80"
                  src="../../assets/img/qnt-logo.png"
                  class="rounded mx-auto d-block"
                />
              </router-link>
              <h3
                v-if="horizontalMenu"
                class="m-header__title"
              >Menu</h3>
            </div>

            <div
              class="m-stack__item m-stack__item--middle m-brand__tools"
              v-if="navbar"
            >

              <!-- BEGIN: Responsive Aside Left Menu Toggler -->
              <a
                href="javascript:;"
                id="m_aside_left_offcanvas_toggle"
                class="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block"
              >
                <span></span>
              </a>
              <!-- END -->

              <!-- BEGIN: Responsive Header Menu Toggler -->
              <a
                v-if="horizontalMenu"
                id="m_aside_header_menu_mobile_toggle"
                href="javascript:;"
                class="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block"
              >
                <span></span>
              </a>

              <!-- END -->
              <button
                class="m-aside-header-menu-mobile-close  m-aside-header-menu-mobile-close--skin-light "
                id="m_aside_header_menu_mobile_close_btn"
              ><i class="la la-close"></i></button>

              <!-- BEGIN: Topbar Toggler -->
              <a
                id="m_aside_header_topbar_mobile_toggle"
                href="javascript:;"
                class="m-brand__icon m--visible-tablet-and-mobile-inline-block"
              >
                <i class="flaticon-more"></i>
              </a>

              <!-- BEGIN: Topbar Toggler -->
            </div>

          </div>
        </div>
        <!-- END: Brand -->
        <div
          class="m-stack__item m-stack__item--middle d-none d-md-table-cell"
          style="width: 100%;"
          v-if="navbar"
        >
        </div>
        <div
          class="m-stack__item m-stack__item--fluid m-header-head"
          id="m_header_nav"
          v-if="navbar"
        >
          <button
            v-if="horizontalMenu"
            class="m-aside-header-menu-mobile-close  m-aside-header-menu-mobile-close--skin-light "
            id="m_aside_header_menu_mobile_close_btn"
          ><i class="la la-close"></i></button>
          <!-- BEGIN: Topbar -->
          <div
            id="m_header_topbar"
            class="m-topbar  m-stack m-stack--ver m-stack--general"
          >
            <div class="m-stack__item m-topbar__nav-wrapper">
              <ul class="m-topbar__nav m-nav m-nav--inline">
                <li
                  class="m-nav__item m-topbar__notifications m-dropdown m-dropdown--large m-dropdown--arrow m-dropdown--align-center 	m-dropdown--mobile-full-width"
                  m-dropdown-toggle="click"
                  m-dropdown-persistent="1"
                >
                  <a
                    href="#"
                    class="m-nav__link m-dropdown__toggle"
                    id="m_topbar_notification_icon"
                  >
                    <span
                      v-if="alerts.length"
                      class="m-nav__link-badge m-badge m-badge--dot m-badge--dot-small m-badge--danger"
                    ></span>
                    <span class="m-nav__link-icon"><span class="m-nav__link-icon-wrapper"><i class="flaticon-alarm"></i></span></span>
                  </a>
                  <div class="m-dropdown__wrapper">
                    <span class="m-dropdown__arrow m-dropdown__arrow--center"></span>
                    <div class="m-dropdown__inner">
                      <div class="m-dropdown__header m--align-center">
                        <span class="m-dropdown__header-title">Notificaciones de usuario</span>
                        <span class="m-dropdown__header-subtitle">{{countNotifications}}</span>
                      </div>
                      <div class="m-dropdown__body">
                        <div class="m-dropdown__content">
                          <ul
                            class="nav nav-tabs m-tabs m-tabs-line m-tabs-line--brand"
                            role="tablist"
                          >
                            <li class="nav-item m-tabs__item">
                              <a
                                class="nav-link m-tabs__link active"
                                data-toggle="tab"
                                href="#topbar_notifications_notifications"
                                role="tab"
                              >
                                Alertas
                              </a>
                            </li>
                          </ul>
                          <div class="tab-content">
                            <div
                              class="tab-pane active"
                              id="topbar_notifications_notifications"
                              role="tabpanel"
                            >
                              <div
                                class="m-scrollable"
                                data-scrollable="true"
                                data-height="250"
                                data-mobile-height="200"
                              >
                                <div class="m-list-timeline m-list-timeline--skin-light">
                                  <div class="m-list-timeline__items">
                                    <div
                                      class="m-list-timeline__item"
                                      v-for="alert in alerts"
                                      :key="alert.id"
                                      alert
                                      @click="switchAlert(alert.id)"
                                      data-toggle="modal"
                                      data-target="#m_modal_1_2"
                                    >
                                      <span :class="'m-list-timeline__badge -m-list-timeline__badge--state-'+ alert.type"></span>
                                      <span class="m-list-timeline__text">{{alert.subject}}</span>
                                      <span class="m-list-timeline__time">{{alert.create_at | moment("from", true)}}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  class="m-nav__item m-topbar__user-profile  m-dropdown m-dropdown--medium m-dropdown--arrow  m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
                  m-dropdown-toggle="click"
                >
                  <a
                    href="#"
                    class="m-nav__link m-dropdown__toggle"
                  >
                    <span class="m-topbar__userpic m--hide">
                      <!--
													<img src="assets/app/media/img/users/user4.jpg" class="m--img-rounded m--marginless m--img-centered" alt="" />
                          -->
                    </span>
                    <span class="m-nav__link-icon m-topbar__usericon">
                      <span class="m-nav__link-icon-wrapper"><i class="flaticon-user-ok"></i></span>
                    </span>
                    <span class="m-topbar__username m--hide">{{name}}</span>
                  </a>
                  <div class="m-dropdown__wrapper">
                    <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                    <div class="m-dropdown__inner">
                      <div class="m-dropdown__header m--align-center">
                        <div class="m-card-user m-card-user--skin-light">
                          <div class="m-card-user__pic">
                            <!--
																<img src="assets/app/media/img/users/user4.jpg" class="m--img-rounded m--marginless" alt="" />
                                 -->
                          </div>
                          <div class="m-card-user__details">
                            <span class="m-card-user__name m--font-weight-500">{{name}}</span>
                            <!-- <a
                              href=""
                              class="m-card-user__email m--font-weight-300 m-link"
                            >{{email}}</a> -->
                            <li class="m-nav__item">
                              <router-link
                                to="/usuario"
                                class="m-nav__link"
                              >
                                <i class="m-nav__link-icon flaticon-profile-1"></i>
                                <span class="m-nav__link-title">
                                  <span class="m-nav__link-wrap">
                                    <span class="m-nav__link-text">Mi perfil</span>
                                  </span>
                                </span>
                                <br>
                              </router-link>
                            </li>
                          </div>
                        </div>
                      </div>
                      <div class="m-dropdown__body">
                        <div class="m-dropdown__content">
                          <ul class="m-nav m-nav--skin-light">

                            <li
                              class="m-nav__item"
                              @click="logout"
                            >
                              <center><div class="btn m-btn--pill  btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder">Cerrar sesión</div></center>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>

          <!-- END: Topbar -->
        </div>
        <!--<div class="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav"  v-else></div> -->
      </div>
    </div>
  </header>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from "vuex";

export default {
  name: 'Header',
  props: {
    name: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    navbar: {
      type: Boolean,
      default: false
    },
    horizontalMenu: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters({
      alerts: 'alerts/alertsNotRead',
      countNotifications: 'alerts/countAlertsNotRead'
    })
  },
  methods: {
    ...mapActions('alerts', [
      'switchAlert'
    ]),
    ...mapActions('users', [
      'logout'
    ])
  }
}
</script>

<style lang="scss">
.m-stack {
  height: unset;
}

.header-container {
  background-color: #ffffff;
  z-index: 10;
  height: unset;

  .logo-container {
    width: 100%;

    img {
      max-width: 80px;
      height: auto;
      padding: 5px 0;
      display: block;
      margin: auto;
    }
  }

  .header-title {
    color: #000000;
    display: block;
    text-align: center;
    margin: auto;
    font-size: 12px;
  }
}

.header-container-in {
  background-color: #ffffff;
  padding: 4px 0;
}

@media (min-width: 1025px) {
  .m-brand .m-brand__logo {
    height: 45px !important;
    max-height: 80px;
    height: unset;
  }

  .m-header {
    &--fixed .m-body {
      padding-top: 65px !important;
    }
    .m-header-head {
      -webkit-box-shadow: unset;
      box-shadow: unset;
    }
  }

  .m-dropdown.m-dropdown {
    &--align-center.m-dropdown--large .m-dropdown__wrapper {
      margin-left: -(190px+60px * 2);
    }

    &--arrow .m-dropdown__arrow {
      margin-left: -20px +60px * 2;
    }
  }
}

@media (max-width: 1024px) {
  .m-header {
    &--fixed-mobile .m-body {
      -webkit-transition: padding-top;
      transition: padding-top;
      padding-top: 60px !important;
    }
  }

  .m-brand {
    height: unset !important;
    .m-brand__logo .m-brand__logo-wrapper img {
      max-width: 80px;
    }
  }
}

.m-dropdown.m-dropdown--align-center .m-dropdown__wrapper {
  margin-left: -(122.5px+40px * 2);
}

.m-page--wide .m-header,
.m-page--fluid .m-header {
  background-color: #ffffff;
}
.m-brand.m-brand--skin-light {
  background: #ffffff;
}
</style>
