<template>
  <div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body"> 
      
      <!-- BEGIN :: Main page right -->
      <div class="m-grid__item m-grid__item--fluid m-wrapper">
      <router-view/>
    </div>
  </div>
</template>


<script>
import { mapState, mapGetters } from "vuex";

export default {
  name:"DashBoard",
  created(){
    this.$store.dispatch("users/getUserData");
  }
}


</script>