<template>
  <div id="m_aside_left" class="m-grid__item	m-aside-left  m-aside-left--skin-light ">
    <!-- BEGIN: Aside Menu -->
    <div id="m_ver_menu" class="m-aside-menu  m-aside-menu--skin-light m-aside-menu--submenu-skin-light "
      data-menu-vertical="true" m-menu-scrollable="1" m-menu-dropdown-timeout="500">
      <ul class="m-menu__nav  m-menu__nav--dropdown-submenu-arrow ">
        <li class="m-menu__item " aria-haspopup="true" m-menu-link-redirect="1">
          <router-link to="/" class="m-menu__link"><i
              class="m-menu__link-icon material-symbols-outlined">home</i><span class="m-menu__link-text">Inicio</span></router-link>
        </li>
        <li class="m-menu__item " aria-haspopup="true" m-menu-link-redirect="1">
          <router-link to="/plan" class="m-menu__link"><i
              class="m-menu__link-icon material-symbols-outlined">check_box</i><span class="m-menu__link-text">Mi plan
              seleccionado</span></router-link>
        </li>
        <li class="m-menu__item " aria-haspopup="true" m-menu-link-redirect="1" v-if="acceptedCreditCard || canceledWithArreasBalanceRP">
          <router-link to='/tdrd' class="m-menu__link"><i
              class="m-menu__link-icon material-symbols-outlined">credit_card</i><span class="m-menu__link-text">Mi Tarjeta de 
              Rebancarización</span></router-link>
        </li>
        <li class="m-menu__item " aria-haspopup="true" m-menu-link-redirect="1">
          <router-link to='/pagos' class="m-menu__link"><i
              class="m-menu__link-icon material-symbols-outlined">savings</i><span class="m-menu__link-text">Mis
              pagos</span></router-link>
        </li>
        <li class="m-menu__item " aria-haspopup="true" m-menu-link-redirect="1">
          <router-link to="/contratos" class="m-menu__link"><i
              class="m-menu__link-icon material-symbols-outlined">post_add</i><span class="m-menu__link-text">Mis
              contratos</span></router-link>
        </li>
        <!-- Paz y salvos -->
        <li class="m-menu__item " aria-haspopup="true" m-menu-link-redirect="1">
          <router-link to="/pazysalvo" class="m-menu__link"><i
              class="m-menu__link-icon material-symbols-outlined">handshake</i><span class="m-menu__link-text">Mi paz y
              salvo</span>
          </router-link>
        </li>
        <li class="m-menu__item " aria-haspopup="true" m-menu-link-redirect="1">
          <router-link to="/notificaciones" class="m-menu__link "><i
              class="m-menu__link-icon material-symbols-outlined">notifications_active</i><span
              class="m-menu__link-text">Alertas / Notificaciones</span></router-link>
        </li>
        <li v-if="activeCreditData" class="m-menu__item " aria-haspopup="true" m-menu-link-redirect="1">
          <router-link to="/datos-crediticios" class="m-menu__link "><i
              class="m-menu__link-icon flaticon-graphic"></i><span class="m-menu__link-text">Mis datos de crédito</span>
          </router-link>
        </li>
      </ul>
    </div>
    <!-- END: Aside Menu -->
  </div>
</template>



<script>
import { mapGetters } from "vuex"
export default {
  name: 'Menu',
  props: {
    activeCreditData: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      acceptedCreditCard: 'products/acceptedOrDelivered',
      canceledWithArreasBalanceRP: 'products/canceledWithArreasBalanceRP',
    })
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 1025px) {

  .m-aside-left--fixed.m-header--static .m-aside-left,
  .m-aside-left--fixed.m-header--fixed .m-aside-left {
    top: 65px;
  }
}

.material-symbols-outlined {
  font-size: 37px !important;
  font-variation-settings:
    'FILL' 0,
    'wght' 200,
    'GRAD' 0,
    'opsz' 60,

}
</style>